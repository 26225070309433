<!-- views/NotFound.vue -->

<template>
  <div>
        <div style="width: 84%;margin-left: 7%;margin-top: 20%">
            <p class="SecondTitle">
                Page not using
            </p>
        </div>


    </div>
</template>

<script>
    import {
        Button, Cell, CellGroup, Divider, DropdownItem, DropdownMenu,
        Field, Switch, Checkbox, CheckboxGroup
    } from 'vant';

    export default {
        name: "NotFound",
        components: {
            [CellGroup.name]: CellGroup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Switch.name]: Switch,
            [Cell.name]: Cell,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Divider.name]: Divider,
            [Button.name]: Button,
            [Field.name]: Field
        },
        data() {
            // SECRET: ''
            return {};
        },
        methods: {
        },
        mounted() {
            this.SECRET = process.env.VUE_APP_NOT_SECRET_CODE
            console.log(process.env.VUE_APP_NOT_SECRET_CODE)
        },
    }
</script>
